import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/evolving-threat/",
    label: "EOLVING THREAT",
  },
]

const resourcesList = [
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMEDR",
    subtitle: "Managed Endpoint Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    cat: "SUCCESS STORY",
  },
  {
    title: "VigilantMNDR",
    subtitle: " Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="EVOLVING THREAT" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-et">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>EVOLVING THREAT</h1>
              <h4>UNDERSTANDING ADVANCED THREATS</h4>
              <p className="content">
                Bad Actors are ever-creating threats that beat existing
                products. Each company must make cyber defense changes at a
                commensurate speed to the evolution of those threats.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    stop threats today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-overview">
      <div className="container">
        <br />
        <div className="row pb-5">
          <div className="col-sm-12">
            <div className="section-col-rr">
              <h4>Making You Just as Cunning as the Enemy</h4>
              <hr />
            </div>

            <div className="row">
              <div className="col-6">
                <p>
                  When enemy forces want to win, what do they do? They break the
                  rules of warfare. They ignore the Geneva Convention, put
                  civilians in harm’s way, or use chemical/biological weapons.
                  In other words, they cheat.
                </p>
                <p>
                  It is the same with threat actors in the cyber world. They can
                  buy every “off the shelf” cybersecurity tool, put it inside
                  their lab and test it to find ways to cheat (by the way, this
                  is one reason that Vigilant’s tech is proprietary, protected
                  and why we never reveal the names of our clients.) These cyber
                  terrorists are ruthless and are always inventing new ways to
                  harm you, your customers, and your treasures. They rarely hit
                  you head on. They sneak in the shadows and do their damage
                  before you even know it.
                </p>
                <p>
                  In the art of cyber war, Vigilant assists companies to be just
                  as ruthless and just as cunning as the enemy. Within our
                  collaborative cybersecurity environment, we too are testing
                  all the tools and evolving our techniques, tactics, and
                  procedures with and for our clients.
                </p>
              </div>
              <div className="col-6">
                <p>
                  Security is warfare, but it is also a race. And in any race
                  you are either ahead of the competition or you are falling
                  behind. This is why we are always ingesting the most data. It
                  is why we don’t just analyze; we hunt for attempted
                  intrusions. And it is why we do all of this within our
                  collaborative framework, customized for each of our client
                  partners.
                </p>
                <p>
                  Threat attempts? Vigilant can see it as it happens – not
                  after. Not in a log – but in real-time. We have full aperture
                  visibility through proprietary sensors inside your systems and
                  omnidirectional visibility all around your system. That
                  tsunami of data is brought into our Adaptive Intelligence
                  Process, allowing full capture analysis, in-process threat
                  identification, verification as “true positive” and valuation
                  of significance. We collaborate with your team, fully
                  weaponized to assist your remediation, providing Attack
                  Surface Resilience. We then scrub and restore whatever damage
                  was attempted, flag potential remnants and continue our 24/7
                  watch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h2>
                Sign Up Today for the Industry-Leading Vigilant <br /> Threat
                Audit
              </h2>
              <hr />
              <Link
                to="/security-threat-audit/"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    sign up today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-platform ">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="side-binder-r">
              <h6>BLOG</h6>
              <h3>First to Detect but Never Publicize</h3>
            </div>

            <p className="content">
              Microsoft had a vulnerability in Microsoft Exchange that allowed
              threat actors to gain access to the run commands inside the
              Exchange servers themselves. The vulnerability had been there for
              years. Because Vigilant is a company fueled by human intelligence
              (just like the adversaries) and ever-adaptive automated
              intelligence, we saw this attack pattern very early on, before
              Microsoft’s announcement.
            </p>
            <div className="ctizzle">
              <Link
                to="/blog-pages/evolving-threat/"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    read more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <StaticImage
              src="../images/security-maturity/hexET.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
